import React from "react";
import { Link } from "gatsby";
import coverplaceholder from "../images/juzycook-icon-s.png";

const CategoryCard = ({ category }) => {
	return (
		<Link to={`/category/${category.node.Slug}`} className="no-underline">
			<div
				id="category-card"
				className="flex flex-row rounded shadow-lg border border-yellow-cust1 p-3 m-3 lg:m-5"
			>
				<div className="bg-cover bg-center bg-no-repeat bg-yellow-cust2">
					<img
						className="w-12"
						src={coverplaceholder}
						alt={category.node.Name}
					></img>
				</div>
				<div class="w-32 p-3 text-xl text-orange-cust1 font-bold">
					{category.node.Name}
				</div>
			</div>
		</Link>
	);
};

export default CategoryCard;
