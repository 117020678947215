import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import Img from "gatsby-image";
import Card from "../components/card";
import CategoryCard from "../components/categorycard";
import Searchbar from "../components/searchbar";

const IndexPage = () => (
	<StaticQuery
		query={graphql`
			query {
				allFile(filter: { relativeDirectory: { eq: "index" } }) {
					edges {
						node {
							name
							relativePath
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
				allStrapiRecipe(
					limit: 9
					filter: { Publish: { eq: true } }
					sort: { order: [DESC, DESC], fields: [Sorting, updatedAt] }
				) {
					edges {
						node {
							Name
							Description
							Coverphoto {
								publicURL
							}
							Slug
							styles {
								Name
								id
								Slug
							}
							categories {
								Name
								id
								Slug
							}
							strapiId
						}
					}
				}
				allStrapiCategory {
					edges {
						node {
							Name
							Slug
						}
					}
				}
			}
		`}
		render={(data) => (
			<Layout title="今晚食咩好？">
				<section className="pb-10 lg:py-10">
					<div className="lg:hidden">
						<h1 className="text-center text-4xl py-5">
							＼今晚食咩好／
						</h1>
					</div>
					<div className="hidden lg:flex flex-row w-4/6 mx-auto">
						<div className="w-48">
							<Img
								fluid={
									data.allFile.edges[0].node.childImageSharp
										.fluid
								}
								alt=""
							/>
						</div>

						<div class="estrellas">
							<span>／</span>
							<span>好</span>
							<span>咩</span>
							<span>食</span>
							<span>晚</span>
							<span>今</span>
							<span>＼</span>
						</div>
						<div className="w-48">
							<Img
								fluid={
									data.allFile.edges[1].node.childImageSharp
										.fluid
								}
								alt=""
							/>
						</div>
					</div>
					<div className="w-4/5 lg:w-1/4 mx-auto">
						<Searchbar />
					</div>
				</section>

				<section>
					<div className="w-full py-2 lg:py-5 border-t-2 border-b-2 bg-yellow-cust3 border-yellow-cust1">
						<h2 className="w-full lg:w-2/5 mx-auto text-center text-xl lg:text-3xl">
							呢期食過咩？
						</h2>
					</div>
					<div className="container mx-auto py-10 flex flex-col lg:flex-row lg:flex-wrap justify-center">
						{data.allStrapiRecipe.edges.map((recipe, i) => {
							return <Card recipe={recipe} />;
						})}
					</div>
				</section>

				<section>
					<div className="w-full py-2 lg:py-5 border-t-2 border-b-2 bg-yellow-cust3 border-yellow-cust1">
						<h2 className="w-full lg:w-2/5 mx-auto text-center text-xl lg:text-3xl">
							食譜種類一覽
						</h2>
					</div>
					<div className="container mx-auto py-10 flex flex-col lg:flex-row lg:flex-wrap justify-center">
						{data.allStrapiCategory.edges.map((category, i) => {
							return <CategoryCard category={category} />;
						})}
					</div>
				</section>
			</Layout>
		)}
	/>
);

export default IndexPage;
